import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router';
import { useStateValue } from '../../stateProvider/stateProvider';
import { USER,ADMIN } from '../../constans/roles';
import './Creation.css'

import {
    FilterSelect
} from '../Filter/Filter';
import { SET_EDIT_ID } from '../../constans/reducerActions';

export const CreationSchema = ({
    click,
    clear,
    setClear,
    filtersData
}) => {
    const [{role},dispatch] = useStateValue();
    console.log(filtersData)

    return (
        <tr className="creation__wrap creation-headers">
            <td className='rel'>

            <FilterSelect 
                title='Kampania' 
                val="Kampania" 
                filtr={click}
                opts={filtersData.Kampania} 
                clear={clear} 
                setClear={setClear}>    
            </FilterSelect>
            </td>
            <td className='rel'>
            <FilterSelect 
             filtr={click}
                title='Klient' 
                val="Klient" 
                opts={filtersData.Klient}  
                clear={clear} 
                setClear={setClear}>    
            </FilterSelect>
            </td>
            <td className='rel'>
            <FilterSelect 
             filtr={click}
                title='Format' 
                val="Format" 
                opts={filtersData.Format} 
                clear={clear} 
                setClear={setClear}>    
            </FilterSelect>
            </td>
            <td className='rel'>


            <FilterSelect 
             filtr={click}
                title='Branża' 
                val="Kategoria" 
                opts={filtersData.Kategoria} 
                clear={clear} 
                setClear={setClear}>    
            </FilterSelect>
            </td>
 
            <td className='td-link'>
            <h3 className='filter__title creation-header__center-column-txt'>Link</h3>
            </td>
            <td className='rel-small'>
               <h3 className='filter__title creation-header__center-column-txt'>Kopiuj link</h3>
            </td>
            {
                (role === USER || role === ADMIN) &&
                <td className='rel-small'>
                    <h3 className='filter__title creation-header__center-column-txt'>Edytuj</h3>
                </td>
            }
            {
                role === ADMIN &&
                <td className='rel-small'>
                    <h3 className='filter__title creation-header__center-column-txt'>Usuń</h3>
                </td>
            }
        </tr>
    )
}


const Creation = ({item,click}) => {
    const [{role},dispatch] = useStateValue()

    const nav = useNavigate();
    const handleCopy = e => {
        navigator.clipboard.writeText(item.Link)
        //e.target.children[0].style.transform = "scale(.9) "
    }
    
    const edit = () => {
        dispatch({type:SET_EDIT_ID,item:item._id})
        //document.body.style.overflow = "hidden"
        document.querySelector('.edit-fixed').style.display = "block"
    }

    return (
        <tr className="creation__wrap">
            <td className="rel">
                {item.Kampania}
            </td>
            <td className="rel">
                {item.Klient}
            </td>
            <td className="rel">
                {item.Format}
            </td>
            <td className="rel">
                {item.Kategoria}
            </td>

            <td className='td-link'>
               <a target="_blank" href={item.Link}>{item.Link}</a>
            </td>
            <td className='rel-small height-med-btn' onClick={handleCopy}>
               <button className="edit-btn button center " >Kopiuj</button>
            </td>
            {
                (role === USER || role === ADMIN) && 
                <td className="rel-small height-med-btn">
                    <button onClick={edit} className="edit-btn button center">Edytuj</button>
                </td>
            }
            {
                role === ADMIN&&
                <td className="rel-small height-med-btn">
                    <button onClick={click} className=" button remove-btn center ">Usuń</button>
                </td>
            }
        </tr>
    )
}

export default Creation