
export const createQueryString = (query) => {
    let queryString = ""



    if(query.Kampania){
        let Kampania = query.Kampania.replace("&","[AND]")
        Kampania = Kampania.replace("+","[PLUS]")
        Kampania = Kampania.replace("#","[HASH]")

        console.log(Kampania)
        queryString += `Kampania=${Kampania}&`
    }

    if(query.Klient){
        let klient  = query.Klient.replace("&","[AND]")
        klient = klient.replace("+","[PLUS]")

        queryString += `Klient=${klient}&`
    }

    if(query.Kategoria)
        queryString += `Kategoria=${query.Kategoria}&`
    
    if(query.Format != "")
        queryString += `Format=${query.Format.replace("&","[AND]")}&`

    return queryString
}


