import { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router";
import { useStateValue } from "../../stateProvider/stateProvider";
import { AddEditSelect } from "../Filter/Filter";
import gsap from "gsap";
import Back from "../Back/Back";
import "./Edit.css";

const Edit = ({ server, getData, formaty,categories,setInfoType,infoType,setInfo,info }) => {
  const navigate = useNavigate();
  const [item, setItem] = useState();
  const [load, setLoad] = useState(false);
  const [campaign, setCampaign] = useState("");
  const [client, setClient] = useState("");
  const [format, setFormat] = useState("");
  const [cat, setCat] = useState("");
  const [link, setLink] = useState("");
  const editFixed = useRef();

  const [{ editID, choosenTable }, dispatch] = useStateValue();

  const handleChange = (type, e) => {
    if (type === "campaign") setCampaign(e.target.value);
    if (type === "client") setClient(e.target.value);
    if (type === "format") setFormat(e.target.value);
    if (type === "cat") setCat(e.target.value);
    if (type === "link") setLink(e.target.value);
  };

  async function getRecord() {
    if (!editID) return;

    setLoad(true);
    const data = await fetch(server + choosenTable + "/getOne/" + editID, {
      headers: {
        "x-auth-token": localStorage.getItem("SLIDESHOW-TOKEN"),
      },
    });
    const json = await data.json();

    setCampaign(json.Kampania);
    setCat(json.Kategoria);
    setClient(json.Klient);
    setFormat(json.Format);
    setLink(json.Link);
    setItem(json);
    setLoad(false);
    console.log(json);
  }
  const handleClick = (e) => {
    e.preventDefault();
    addData();

  };
  const handleClear = (e) => {
    e.preventDefault();
    setCampaign("");
    setCat("");
    setClient("");
    setFormat("");
    setLink("");
  };

  async function addData() {
    try {
      if (!editID) return;
      const res = await fetch(server + choosenTable + "/edit/" + editID, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-auth-token": localStorage.getItem("SLIDESHOW-TOKEN"),
        },
        body: JSON.stringify({
          Kampania: campaign,
          Klient: client,
          Link: link,
          Format: format,
          Kategoria: cat,
        }),
      });

      console.log(res.status);
      const aler = await res.json();
      
      setInfoType("Succes")
      setInfo("Udało się zedytować!")
      if (res.status === 200) {
        getData();
        gsap.to(editFixed.current,{opacity:0,duration:.3,onComplete: () => {
          editFixed.current.style.display = "none";
          gsap.set(editFixed.current,{opacity:1})
        }})
      }
      else if (res.status === 401 || res.status === 400) {
        alert(aler);
      }
    } catch (err) {
      
      setInfoType("Fail")
      setInfo("Nie udało się zedytować!")
    }
  }

  useEffect(() => {
    if (!localStorage.getItem("SLIDESHOW-TOKEN")) navigate("/login");

    getRecord();
  }, [editID]);

  return (
    <div className="edit-fixed" ref={editFixed}>
      <div onClick={() => setLoad(true)} className="back-btn">
        <Back />
      </div>
      <div className="edit-wrap">
        {item && !load ? (
          <form className="add-form">
                      
                      <div className="add-input-div  add-div-small">
                <label 
                className="add-label"

                htmlFor="campaign">
                    Kampania: </label>
                    
                <input type="text" 
                placeholder="Wpisz"
                name="campaign" value={campaign}                 
                onChange={e => handleChange('campaign',e)} />
            </div>
            <div className="add-input-div  add-div-small">
                <label
               
                className="add-label"
                 htmlFor="client">Klient: </label>
                 
                <input type="text" 
                 placeholder="Wpisz"
                name="client" value={client} 
                onChange={e => handleChange('client',e)} />
            </div>
            <AddEditSelect 
              val={format}
                title="Format"
                defaultt={format}
                opts={formaty}
                set={setFormat}
            />
            <AddEditSelect 
                val={cat}
                title="Kategoria"
                defaultt={""}
                opts={categories}
                set={setCat}
            />
            {
              /*
            <div className="add-input-div add-div-small">
                <label 
                className="add-label"
                htmlFor="cat">Kategoria: </label>
                
                <input 
                placeholder="Wpisz"
                    type="text" 
                    name="cat" 
                    value={cat} 
                    onChange={e => handleChange('cat',e)} 
                />
            </div>
            */
          }
            <div className="add-input-div add-div-medium">
                <label 
                className="add-label"
                htmlFor="link">Link: </label>
                
                <input 
                placeholder="Wklej"
                    type="text" 
                    name="link" 
                    value={link}  
                    onChange={e => handleChange('link',e)} 
                />
            </div>
            <div className="add-button-div">
            <button 
                className="add-submit " 
                type="submit"
                onClick={handleClick}
            >Edytuj
            </button>
            
         
{
  /* 
    <button
                className="add-submit margin-left"
                type="submit"
                onClick={handleClear}
              >
                Wyczyść
              </button>
  
  */
}
            
            </div>
          </form>
        ) : (
          <div>Loading...</div>
        )}
      </div>{" "}
    </div>
  );
};

export default Edit;
