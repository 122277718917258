import { WIZUALIZACJE,ZAKODOWANE } from "../constans/tableCalls";

export const defaultQuery = {
    Kampania: "",
    Format: "",
    Kategoria: "",
    Klient: "",
  }

export const initialState = {
    role: "",
    editID: "",
    query: defaultQuery,
    allPages: [],
    currentPage: 1,
    filtersActive: [],
    paggingQuery: defaultQuery,
    filterData: '',
    choosenTable: ZAKODOWANE,
};
