export const SET_ROLES = "SET_ROLES";
export const LOGOUT = "LOGOUT"
export const SET_QUERY = "SET_QUERY"
export const SET_FILTERS_DATA = "SET_FILTERS_DATA"
export const SET_ALL_PAGES = "SET_ALL_PAGES"
export const SET_CURRECT_PAGE = "SET_CURRECT_PAGE"
export const SET_ACTIVE_FILTERS = "SET_ACTIVE_FILTERS"
export const SET_PAGGING_QUERY = "SET_PAGGING_QUERY"
export const SET_EDIT_ID = "SET_EDIT_ID"
export const SET_CHOOSEN_TABLE = "SET_CHOOSEN_TABLE"
   