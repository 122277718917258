import { WIZUALIZACJE,ZAKODOWANE } from "../../constans/tableCalls"
import { useStateValue } from "../../stateProvider/stateProvider"
import { SET_CHOOSEN_TABLE } from "../../constans/reducerActions";
import { useNavigate } from "react-router";

import './ChooseTable.css'

export const ChooseTable = () => {
    const [reducer,dispatch] = useStateValue();
    const nav = useNavigate();

    const clickWizki = () => {
        dispatch({
            type: SET_CHOOSEN_TABLE,
            item: WIZUALIZACJE,
        })

        nav("/")
    }
    const clickKreacje = () => {
        dispatch({
            type: SET_CHOOSEN_TABLE,
            item: ZAKODOWANE,
        })

        nav("/")
    }

    return (
        <div className="choose-wrap">
            <div onClick={clickWizki} className="choose-div margin-left "> 
                <img src="./Images/de.png" className="choose-logo"/>
                <h2 className="choose-txt">Wizualizacje statyczne</h2>
            </div>
            <div onClick={clickKreacje} className="choose-div margin-right "> 
                <img src="./Images/de.png" className="choose-logo"/>
                <h2 className="choose-txt">Kreacje zakodowane</h2>
            </div>
        </div>
    )
}

