import React,{useState} from 'react';
import './Filter.css'
import {clearFilterElement } from '../Clear/Clear'
import { createQueryString } from '../../Functions/queryFunctions';
import { useStateValue } from '../../stateProvider/stateProvider';
import { SET_ACTIVE_FILTERS, SET_QUERY } from '../../constans/reducerActions';


export const AddEditSelect = ({
    title,
    opts,val,
    defaultt,
    set,}) => {
    const [value,setValue] = useState(!defaultt ? "" : defaultt);

    const options =  opts.map(item => <option key={item} name={title} id={item} value={item}>{item}</option>)

    const handleChange = e => {
       
        set(e.target.value)
        setValue(e.target.value)
    }

    return (
        <div className="add-input-div  add-div-small add-select-in">
            <p className="add-label add-pp">{title}</p>
            <p className="add-label add-p">{title}</p>
            <div className="add-input-select">
            <select name={title} onChange={handleChange} value={val} >
                <option 
                    name={title} 
                    id="0" 
                    value={' '} 
                >
                    {' '}
                </option>)
                {options}
            </select>
            <img src="./Images/arr.png" className='filter__select-arrow'/>
            </div>
        </div>
    )
}

export const FilterSelect = ({
    title,
    opts,
    filtr,
    val,
    clear,
    setClear,}) => {
    const [value,setValue] = useState("");
    const [{query},dispatch] = useStateValue();

    const options =  opts.map(item => <option key={item} name={title} id={item} value={item}>{item}</option>)
    clearFilterElement(setValue,clear,value) 

    const handleChange = e => {
        let nQuery = {...query};
        if(e.target.value === " ")
            nQuery[val] = "";
        else
            nQuery[val] = e.target.value;

        setClear(false)
        dispatch({type:SET_QUERY,item:nQuery})
        setValue(e.target.value)


        console.log(createQueryString(nQuery))
        filtr(createQueryString(nQuery),nQuery)
    }

    return (
        <div className="filter__wrap">
            <h3 className="filter__title">{title}</h3>
            <input 
                type="text" 
                placeholder='wybierz'
                className="filter__input" 
                value={value}
                onInput={handleChange}    
            />
            <div className="filter__select">
            <select name={title} onChange={handleChange} value={value} >
                <option 
                    name={title} 
                    id="0" 
                    value={' '} 
                >
                    {' '}
                </option>)
                {options}
            </select>
            <img src="./Images/arr.png" className='filter__select-arrow'/>
            </div>
        </div>
    )
}

export const FilterInput = ({
    title,
    set,
    val,
    query,
    clear,
    setClear,
}) =>  {
    const [value,setValue] = useState('')
    clearFilterElement(setValue,clear,value) 

    const handleInput = e => {
        let nQuery = {...query};
        nQuery[val] = e.target.value;



        setClear(false)
        setValue(e.target.value)
        set(nQuery)
    }

    return (
        <div className="filter__wrap">
            <h3 className="filter__title">{title}</h3>
            <input 
                type="text" 
                className="filter__input" 
                value={value}
                onInput={handleInput}    
            />
        </div>
    )}


export const FilterBtn = ({name,click,width = false, addClass=""}) => (
    <div className={`filter__btn-wrap ${width && 'filter-btn-wrap-v2' } `}>
        <button className={`filter__btn ${width && 'filter-btns'} ${addClass}`} onClick={click}>
            {name}
        </button>
        </div>

    )

export const FilterPages = ({current,click,query,docs}) =>{

 return(
    <div className="filter__btn-wrap">
        <div className='filters__btn-center'>
        Strony:
         {current > 2 
        ? (<span className="filter__page-link" onClick={() => click(query,1)}>{1}</span>)
        : null
        }

        {current <= 1 
        ? null 
        : (<span className="filter__page-link" onClick={() => click(query,current -1)}>{current - 1}</span>)
        }
        
        {"\t"}
        {<span className="filter__page-link filter__page-link--current" >{current}</span>}
        {"\t"}

        {current >= 1 && docs > 2 && current + 1< docs 
        ?  (<span className="filter__page-link" onClick={() => click(query,current +1)}>{`${current + 1}\t`}</span>)
        :   null
        }

        {current >= 1 && docs > 3 && current + 2< docs 
        ?  (<span className="filter__page-link" onClick={() => click(query,current +2)}>{`${current + 2}\t`}</span>)
        :   null
        }

{current >= 1 && docs > 4 && current + 3< docs 
        ?  (<span className="filter__page-link" onClick={() => click(query,current +3)}>{`${current + 3}\t`}</span>)
        :   null
        }

{current >= 1 && docs > 5 && current + 4< docs 
        ?  (<span className="filter__page-link" onClick={() => click(query,current +4)}>{`${current + 4}\t`}</span>)
        :   null
        }

{current >= 1 && docs > 6 && current + 5< docs 
        ?  (<span className="filter__page-link" onClick={() => click(query,current +5)}>{`${current + 5}\t`}</span>)
        :   null
        }

        {current >= docs 
        ? null 
        : (<span className="filter__page-link" onClick={() => click(query,docs)}>{docs}</span>)
        }

        {current >= 1 && docs > 2 && current + 1< docs 
        ?  (<img className="filter__page-link-img" src="./Images/arr-right.png" onClick={() => click(query,current +1)}/>)
        :   null
        }
        </div>
    </div>
)}



export const ActiveFillters = ({set}) => {
    const [{query},dispatch] = useStateValue();
    let keys = Object.keys(query);
    keys = keys.filter(key => query[key])

    let nQuery = {...query};



    const handleDeleteFilter = (item) => {
        if(item === "Format")
            nQuery = {...query,Format:""}
            if(item === "Klient")
            nQuery = {...query,Klient:""}
            if(item === "Kampania")
            nQuery = {...query,Kampania:""}
            if(item === "Kategoria")
            nQuery = {...query,Kategoria:""}

        console.log(nQuery)
        set(createQueryString(nQuery),nQuery);
        dispatch({type:SET_QUERY,item:nQuery})
    }

    let list = []
    
    list = keys.map(item => {
        
        return (
        <li key={item} className={`filter__active-list-el  filter__active-${item}`}>
        {/*
            <span className='filter__active-list-attr'>{item}</span> 
            {'\t'}
            : 
            {'\t'}
        */}
        <span className='filter__active-delete-filter' onClick={() => handleDeleteFilter(item)}>X</span>
        <span className={`filter__active-list-value`}>{query[item]}</span>
        </li>
    )})

    return (
        <ul className="filter__active-list">
            <p className=''>Aktywne filtry: </p>
            {list}
        </ul>
    )
}