import React from 'react';
import './Filters.css'
import { useNavigate} from 'react-router-dom'

import {
    FilterBtn, 
    ActiveFillters
} from '../Filter/Filter';
import { useStateValue } from '../../stateProvider/stateProvider';
import { ADMIN, USER } from '../../constans/roles';

export const Filters = ({
    getAll,
    filtersActive,
    set,
}) => {
    const [{role},dispatch] = useStateValue()
    const navigate = useNavigate();

    const add = () => 
        document.querySelector('.add').style.display = "block";
        
    return (
        <div className="filters">
            <ActiveFillters query={filtersActive} set={set}></ActiveFillters>

            <div className='filter-btns-wrapp'>

            {
                (role === USER || role === ADMIN) &&
                <FilterBtn 
                    name="Dodaj kreację" 
                    addClass='add-crea'
                    click={add}
                    width={true}
                />
            }
            <FilterBtn 
                name="Wyczyść filtry" click={getAll}
                addClass="clear"
                width={true}/>       

      
            </div>
        </div>
    )
}

