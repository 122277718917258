import {SET_ROLES,
    LOGOUT,
    SET_QUERY,
    SET_ACTIVE_FILTERS,
    SET_ALL_PAGES,
    SET_CURRECT_PAGE,
    SET_FILTERS_DATA,
    SET_EDIT_ID,
    SET_PAGGING_QUERY,
    SET_CHOOSEN_TABLE} from '../constans/reducerActions'
import { initialState } from './init';


const setRoles = (state,action) => {
    if(!action.item.roles)
            return {...state}

        let role = "";

        if(action.item.roles.indexOf('guest') != -1)
            role = "guest"
        if(action.item.roles.indexOf('user') != -1)
            role = "user"
        if(action.item.roles.indexOf('admin') != -1)
            role = "admin"

        return ({
            ...state,
            role,
        })
}

const logOut = (state) => {
    localStorage.setItem('SLIDESHOW-TOKEN', "")
    localStorage.setItem('SLIDESHOW-ROLES', [])
    return (initialState)
}

const setQuery = (state,action) => {
    return ({
        ...state,
        query: action.item
    })
}

const setAllPages = (state,action) => {
    return({
        ...state,
        allPages: action.item
    })
}
const setCurrent = (state,action) => {
    return({
        ...state,
        currentPage: action.item
    })
}
const setFiltersActive = (state,action) => {
    return({
        ...state,
        filtersActive: action.item
    })
}
const setPaggingQuery = (state,action) => {
    return({
        ...state,
        paggingQuery: action.item
    })
}
const setFilterData = (state,action) => {
    return({
        ...state,
        filtersData: action.item
    })
}

const setEditID = (state,action) => {
    return({
        ...state,
        editID: action.item
    })
}

const setChoosenTable = (state,action) => {
    return ({
        ...state,
        choosenTable: action.item,
    })
}

export const reducer = (state,action) => {

    switch(action.type){
        case SET_ROLES: 
            return setRoles(state,action)
            
        case LOGOUT:
            return logOut();

        case SET_QUERY: 
            return setQuery(state,action)

        case SET_FILTERS_DATA: 
            return setFilterData(state,action)

        case SET_ALL_PAGES: 
            return setAllPages(state,action)

        case SET_CURRECT_PAGE: 
            return setCurrent(state,action)

        case SET_ACTIVE_FILTERS: 
            return setFiltersActive(state,action)

        case SET_PAGGING_QUERY: 
            return setPaggingQuery(state,action)

        case SET_EDIT_ID:
            return setEditID(state,action)

        case SET_CHOOSEN_TABLE:
            return setChoosenTable(state,action);
    }
}

export default reducer;

