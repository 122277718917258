import './Back.css'
import { useNavigate } from 'react-router'
import { useStateValue } from '../../stateProvider/stateProvider'
import { SET_EDIT_ID } from '../../constans/reducerActions'

const Back = ({call}) => {
    const [reducer,dispatch] = useStateValue();

    return (
    <span onClick={e => {


        e.preventDefault()
        dispatch({type:SET_EDIT_ID,item:""})
        document.querySelector('.add').style.display = "none";
        document.querySelector('.edit-fixed').style.display = "none";
    }} className="back-btn">
        X
    </span>
)}

export default Back